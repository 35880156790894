import React, { Component } from 'react';

import HomePage from './Homepage';
import Footer from './Footer';

export default class Home extends Component {
  render() {
    return (
      <>
      
        <HomePage />
        {/* <Footer /> */}
      </>
    );
  }
}
