// maison-common
import photo1 from "../images/maison-common/DEV00829-Bearbeitet.jpg";
import photo2 from "../images/maison-common/DEV00905-Bearbeitet.jpg";
import photo3 from "../images/maison-common/DEV00627-Bearbeitet-2.jpg";
import photo4 from "../images/maison-common/Maison-Common-Summer-2019-5357.jpg";
import photo5 from "../images/maison-common/Maison-Common-Summer-2019-5419.jpg";
import photo6 from "../images/maison-common/Maison-Common-Summer-2019-5680.jpg";

// wingate
import photo7 from "../images/wingate/red pleated top and long skirt.jpg";
import photo8 from "../images/wingate/White sweater and two tone pant.jpg";
import photo9 from "../images/wingate/wingate_collection05_2_0119.jpg";
import photo10 from "../images/wingate/wingate_collection05_19_0886.jpg";
import photo11 from "../images/nadina.jpg";
import photo12 from "../images/cape.jpg";
import photo13 from "../images/water lily.jpg";
import photo14 from "../images/barb/_MG_0165.jpg";
import photo15 from "../images/barb/_MG_1859.jpg";
import photo16 from "../images/barb/_MG_3413.jpg";
import photo21 from "../images/wingate/back shot of printed tunic.jpg";
import photo22 from "../images/wingate/wingate_collection05_26_1140.jpg";

// handbags
import photo17 from "../images/handbags/Patti-Garnet--ED.jpg";
import photo18 from "../images/handbags/Paula-II-Raven--ED.jpg";
import photo19 from "../images/handbags/Sidney-Velvet--ED-A-preferred.jpg";
import photo20 from "../images/handbags/Vera-Gold--ED.jpg";

const photos = [
  {
    title: "Maison Common",
    src: photo1,
    width: 2,
    height: 3
  },
  {
    title: "WINGATE",
    src: photo7,
    width: 2,
    height: 3
  },
  {
    title: "Maison Common",
    src: photo6,
    width: 2,
    height: 3
  },
  {
    title: "Barbara Lohmann",
    src: photo13,
    width: 2,
    height: 3
  },
  {
    title: "WINGATE",
    src: photo21,
    width: 3,
    height: 2
  },
  {
    title: "Maison Common",
    src: photo2,
    width: 2,
    height: 3
  },
  {
    title: "Ella McHugh",
    src: photo17,
    width: 2,
    height: 3
  },
  {
    title: "Barbara Lohmann",
    src: photo16,
    width: 2,
    height: 3
  },
  {
    title: "Maison Common",
    src: photo4,
    width: 3,
    height: 2
  },
  {
    title: "Ella McHugh",
    src: photo19,
    width: 2,
    height: 3
  },
  {
    title: "Barbara Lohmann",
    src: photo12,
    width: 2,
    height: 3
  },
  {
    title: "WINGATE",
    src: photo22,
    width: 2,
    height: 3
  },
  {
    title: "Maison Common",
    src: photo5,
    width: 2,
    height: 3
  },
  {
    title: "WINGATE",
    src: photo8,
    width: 2,
    height: 3
  },
  {
    title: "Ella McHugh",
    src: photo20,
    width: 2,
    height: 3
  },
  {
    title: "Maison Common",
    src: photo3,
    width: 5,
    height: 9
  },
  {
    title: "WINGATE",
    src: photo9,
    width: 2,
    height: 3
  },
  {
    title: "Ella McHugh",
    src: photo18,
    width: 2,
    height: 3
  },
  {
    title: "Barbara Lohmann",
    src: photo11,
    width: 2,
    height: 3
  },
  {
    title: "Barbara Lohmann",
    src: photo14,
    width: 2,
    height: 3
  },
  {
    title: "WINGATE",
    src: photo10,
    width: 3,
    height: 4
  },
  {
    title: "Barbara Lohmann",
    src: photo15,
    width: 2,
    height: 3
  }
];

export default photos;
